import React, { createContext, useContext, useState } from "react";
import { useAuth } from "./AuthContext";

const SearchAnimeContext = createContext();

export const useSearchAnime = () => {
  return useContext(SearchAnimeContext);
};

export const SearchAnimeProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [searchResults, setSearchResults] = useState([]);
  const [searchPage, setSearchPage] = useState(1);
  const [searchHasNextPage, setSearchHasNextPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchSearchResults = async (query, page) => {
    if (!currentUser) {
      return { results: [], hasNextPage: false };
    }
    try {
      const response = await fetch(
        `https://consumet.animetv24.com/anime/gogoanime/${query}?page=${page}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch search results");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching search results:", error);
      return { results: [], hasNextPage: false };
    }
  };

  const search = async (query) => {
    setLoading(true);
    try {
      setSearchQuery(query);
      const searchResults = await fetchSearchResults(query, 1);
      setSearchResults(searchResults.results);
      setSearchPage(1);
      setSearchHasNextPage(searchResults.hasNextPage);
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreSearchResults = async () => {
    if (!searchHasNextPage) return;
    setLoading(true);
    try {
      const nextPage = searchPage + 1;
      const searchResults = await fetchSearchResults(searchQuery, nextPage);
      setSearchResults((prevResults) => [
        ...prevResults,
        ...searchResults.results.filter(
          (newResult) =>
            !prevResults.some((prevResult) => prevResult.id === newResult.id)
        ),
      ]);
      setSearchPage(nextPage);
      setSearchHasNextPage(searchResults.hasNextPage);
    } catch (error) {
      console.error("Load more error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SearchAnimeContext.Provider
      value={{
        searchResults,
        search,
        loadMoreSearchResults,
        loading,
        searchHasNextPage,
      }}
    >
      {children}
    </SearchAnimeContext.Provider>
  );
};

import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";

const AnimeInfoContext = createContext();

export function AnimeInfoProvider({ animeId, children }) {
  const currentUser = useAuth();
  const [animeInfo, setAnimeInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [videoSources, setVideoSources] = useState([]);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState("default");
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const fetchAnimeInfo = (id) => {
    if (!currentUser) {
      return;
    }
    setLoading(true);
    setError(null);
    fetch(`https://consumet.animetv24.com/anime/gogoanime/info/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setAnimeInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Error fetching anime info for ${id}:`, error);
        setError(error);
        setLoading(false);
      });
  };

  const fetchEpisode = async (episodeId) => {
    setLoading(true);
    setError(null);
    setIsVideoReady(false);
    try {
      const response = await fetch(
        `https://consumet.animetv24.com/anime/gogoanime/watch/${episodeId}`
      );
      const data = await response.json();
      setVideoSources(data.sources);
      setCurrentEpisode(episodeId);
      setSelectedQuality("default");

      // Find the index of the chunk containing the current episode
      const episodeIndex = animeInfo?.episodes.findIndex(
        (episode) => episode.id === episodeId
      );
      const chunkIndex = Math.floor(episodeIndex / 15); // Adjust chunk size if needed
      setCurrentSlide(chunkIndex);
    } catch (err) {
      setError("Failed to load episode data");
    } finally {
      setLoading(false);
    }
  };

  const handleQualityChange = (e) => {
    setSelectedQuality(e.target.value);
  };

  const getQualityOptions = () => {
    const uniqueQualities = [
      ...new Set(videoSources.map((source) => source.quality)),
    ];
    return uniqueQualities.map((quality) => (
      <option key={quality} value={quality}>
        {quality}
      </option>
    ));
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (animeId) {
      fetchAnimeInfo(animeId);
    }
  }, [animeId, currentUser]);

  return (
    <AnimeInfoContext.Provider
      value={{
        animeInfo,
        fetchAnimeInfo,
        fetchEpisode,
        videoSources,
        isVideoReady,
        selectedQuality,
        handleQualityChange,
        getQualityOptions,
        loading,
        error,
        currentEpisode,
        currentSlide,
        setCurrentSlide,
        setIsVideoReady,
        setCurrentEpisode,
      }}
    >
      {children}
    </AnimeInfoContext.Provider>
  );
}

export function useAnimeInfo() {
  return useContext(AnimeInfoContext);
}

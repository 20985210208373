import React from "react";
import Popular from "./Popular";

const PopularIndex = () => {
  return (
    <div>
      <Popular />
    </div>
  );
};

export default PopularIndex;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Details from "./Details";
import { useAnimeInfo } from "../context/AnimeInfoProvider";

export default function DetailsIndex() {
  const { id } = useParams();
  const { animeInfo, fetchAnimeInfo, loading, error } = useAnimeInfo();

  useEffect(() => {
    if (id) {
      fetchAnimeInfo(id);
    }
  }, [id]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5">
        <h2 className="text-danger">Error: {error.message}.</h2>
      </div>
    );
  }

  return (
    <div className="mt-5">
      {animeInfo ? (
        <Details animeInfo={animeInfo} />
      ) : (
        <div className="text-center mt-5">
          <h2 className="text-danger">Error finding anime.</h2>
        </div>
      )}
    </div>
  );
}

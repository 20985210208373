import React, { createContext, useContext, useState, useEffect } from "react";
import parse from "../utils/parse";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await parse.User.currentAsync();
        setCurrentUser(user);
      } catch (error) {
        console.error("Error fetching current user:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = async (username, password) => {
    try {
      const user = await parse.User.logIn(username, password);
      setCurrentUser(user);
      return user;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const signup = async (username, password, email) => {
    const user = new parse.User();
    user.set("username", username);
    user.set("password", password);
    user.set("email", email);

    try {
      await user.signUp();
      await parse.User.logIn(username, password);
      const sessionToken = parse.User.current().getSessionToken();
      const query = new parse.Query(parse.Role);
      query.equalTo("name", "user");
      const role = await query.first({ sessionToken });

      if (role) {
        role.getUsers().add(user);
        await role.save(null, { sessionToken });
      } else {
        console.error("Role not found", role);
      }
      await parse.User.logOut();
      return user;
    } catch (error) {
      console.error("Error while signing up user:", error);
    }
  };

  const logout = async () => {
    try {
      await parse.User.logOut();
      setCurrentUser(null);
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  };

  const isAuthenticated = () => {
    return !!currentUser;
  };

  const authContextValue = {
    currentUser,
    login,
    logout,
    isAuthenticated,
    loading,
    signup,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

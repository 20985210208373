import React, { useRef, useEffect, useState } from "react";
import Hls from "hls.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAnimeInfo } from "../context/AnimeInfoProvider";

export default function Player() {
  const {
    animeInfo,
    fetchEpisode,
    videoSources,
    isVideoReady,
    selectedQuality,
    handleQualityChange,
    getQualityOptions,
    loading,
    error,
    currentEpisode,
    currentSlide,
    setCurrentSlide,
    setIsVideoReady,
    setCurrentEpisode,
  } = useAnimeInfo();

  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const [videoLoading, setVideoLoading] = useState(true);
  const [playbackTime, setPlaybackTime] = useState(0);

  useEffect(() => {
    if (animeInfo?.episodes.length > 0) {
      const defaultEpisodeId = animeInfo.episodes[0].id;
      if (!currentEpisode) {
        fetchEpisode(defaultEpisodeId);
      }
    }
  }, [animeInfo, currentEpisode, fetchEpisode]);

  useEffect(() => {
    if (videoSources.length > 0 && videoRef.current) {
      const hls = new Hls();
      hlsRef.current = hls;
      const video = videoRef.current;

      const onLoadedData = () => {
        video.currentTime = playbackTime;
        setVideoLoading(false);
      };

      if (Hls.isSupported()) {
        const m3u8Source = videoSources.find(
          (source) => source.isM3U8 && source.quality === selectedQuality
        );
        if (m3u8Source) {
          hls.loadSource(m3u8Source.url);
          hls.attachMedia(video);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            setIsVideoReady(true);
            onLoadedData();
          });
          hls.on(Hls.Events.ERROR, () => {
            setVideoLoading(false);
          });

          return () => {
            hls.destroy();
          };
        }
      } else {
        videoSources.forEach((source) => {
          const sourceElement = document.createElement("source");
          sourceElement.src = source.url;
          sourceElement.type = "video/mp4";
          video.appendChild(sourceElement);
        });
        video.load();
        onLoadedData();
      }
    }
  }, [videoSources, selectedQuality, setIsVideoReady]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener("timeupdate", () => {
        setPlaybackTime(video.currentTime);
      });
    }
    return () => {
      if (video) {
        video.removeEventListener("timeupdate", () => {
          setPlaybackTime(video.currentTime);
        });
      }
    };
  }, [videoRef.current]);

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const episodeChunks = chunkArray(animeInfo?.episodes || [], 15);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    afterChange: (current) => setCurrentSlide(current),
  };

  const handlePreviousEpisode = () => {
    const currentIndex = animeInfo?.episodes.findIndex(
      (episode) => episode.id === currentEpisode
    );
    if (currentIndex > 0) {
      const previousEpisode = animeInfo.episodes[currentIndex - 1];
      setCurrentEpisode(previousEpisode.id);
      fetchEpisode(previousEpisode.id);
      setVideoLoading(true);
    }
  };

  const handleNextEpisode = () => {
    const currentIndex = animeInfo?.episodes.findIndex(
      (episode) => episode.id === currentEpisode
    );
    if (currentIndex < animeInfo?.episodes.length - 1) {
      const nextEpisode = animeInfo.episodes[currentIndex + 1];
      setCurrentEpisode(nextEpisode.id);
      fetchEpisode(nextEpisode.id);
      setVideoLoading(true);
    }
  };

  return (
    <div className="anime__player">
      <div className="row">
        <div className="col-md-8">
          <div className="anime__video__player">
            {loading && !videoSources.length ? (
              <div className="player-loading">
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : error ? (
              <div className="text-danger">{error}</div>
            ) : (
              <>
                <video
                  ref={videoRef}
                  id="player"
                  playsInline
                  controls
                  data-poster={animeInfo?.image}
                  style={{ width: "100%", height: "auto" }}
                  onLoadedData={() => setVideoLoading(false)}
                >
                  <track
                    kind="captions"
                    label="English captions"
                    src="#"
                    srclang="en"
                    default
                  />
                </video>
                {videoLoading && (
                  <div className="video-loading-overlay">
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Preparing video...</span>
                    </div>
                  </div>
                )}
                <div className="video-quality-selector">
                  <label htmlFor="quality-select">Quality:</label>
                  <select
                    id="quality-select"
                    value={selectedQuality}
                    onChange={(e) => {
                      handleQualityChange(e);
                      setVideoLoading(true);
                    }}
                  >
                    {getQualityOptions()}
                  </select>
                </div>
                <div className="video-navigation">
                  <a
                    onClick={handlePreviousEpisode}
                    disabled={
                      animeInfo?.episodes.findIndex(
                        (episode) => episode.id === currentEpisode
                      ) === 0
                    }
                  >
                    Previous
                  </a>
                  <a
                    onClick={handleNextEpisode}
                    disabled={
                      animeInfo?.episodes.findIndex(
                        (episode) => episode.id === currentEpisode
                      ) ===
                      animeInfo?.episodes.length - 1
                    }
                  >
                    Next
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="anime__details__widget">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="anime__details__title">
                  <h3>{animeInfo?.title}</h3>
                  <span>{animeInfo?.otherName}</span>
                </div>
                <p
                  style={{
                    color: "#fff",
                    whiteSpace: "pre-wrap",
                    overflow: "auto",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 5,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {animeInfo?.description}
                </p>
                <ul>
                  <li>
                    <span>Type:</span> {animeInfo?.type}
                  </li>
                  <li>
                    <span>Language:</span> {animeInfo?.subOrDub}
                  </li>
                  <li>
                    <span>Date aired:</span> {animeInfo?.releaseDate}
                  </li>
                  <li>
                    <span>Total Episodes:</span> {animeInfo?.totalEpisodes}
                  </li>
                  <li>
                    <span>Status:</span> {animeInfo?.status}
                  </li>
                  <li>
                    <span>Genre:</span> {animeInfo?.genres.join(", ")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container anime__details__episodes mt-4">
        <div className="section-title">
          <h5>
            {animeInfo?.title} - Ep{" "}
            {animeInfo?.episodes.find((e) => e.id === currentEpisode)?.number}
          </h5>
        </div>
        <Slider {...sliderSettings}>
          {episodeChunks.map((chunk, chunkIndex) => (
            <div key={chunkIndex} className="episode-slider-slide">
              {chunk.map((episode) => (
                <a
                  key={episode.id}
                  href="#"
                  className={`episode-item ${
                    episode.id === currentEpisode ? "active" : ""
                  }`}
                  onClick={() => {
                    setCurrentEpisode(episode.id);
                    fetchEpisode(episode.id);
                    setVideoLoading(true);
                  }}
                >
                  {episode.number}
                </a>
              ))}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

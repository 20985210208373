import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

export default function Navbar() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
      // Optionally, display an error message to the user here
    }
  };

  const isActive = (path) => (location.pathname === path ? "active" : "");

  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="header__logo">
              <Link to="/">
                <img src="/assets/img/logo.png" alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="header__nav">
              {currentUser && (
                <nav className="header__menu mobile-menu">
                  <ul>
                    <li className={isActive("/")}>
                      <Link to="/">Homepage</Link>
                    </li>
                    <li className={isActive("/popular")}>
                      <Link to="/popular">Popular Anime</Link>
                    </li>
                    <li className={isActive("/recent")}>
                      <Link to="/recent">Recent Anime</Link>
                    </li>
                    <li className={isActive("/categories")}>
                      <Link to="/categories">Categories</Link>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="header__right">
              {currentUser ? (
                <div className="dropdown">
                  <Link
                    to="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-label="User menu"
                  >
                    <span className="icon_profile"></span>
                  </Link>
                  <ul
                    className="dropdown-menu border border-light"
                    style={{ backgroundColor: "#070720", color: "white" }}
                  >
                    <li>
                      <Link to="/profile" className="dropdown-item">
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="logout-switch dropdown-item"
                        onClick={handleLogout}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <Link to="/login">Login</Link>
              )}
            </div>
          </div>
        </div>
        <div id="mobile-menu-wrap"></div>
      </div>
    </header>
  );
}

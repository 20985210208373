import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const { login, logout, currentUser } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const initiateLogout = async () => {
      if (currentUser) {
        await logout();
      }
    };
    initiateLogout();
  }, [logout]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setMessage("");
    setError(false);
    setLoading(true);

    try {
      await login(username, password);
      navigate("/");
    } catch (err) {
      setError(true);
      setMessage(
        "Failed to login. Please check your credentials and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-6 bg-transparent text-light">
          <div className="card p-4 bg-transparent">
            <h3 className="card-title text-center text-light fw-bold">
              Login to Your Account
            </h3>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label>Email or Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email or Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {message && (
                <div
                  className={`alert ${
                    error ? "alert-danger" : "alert-success"
                  }`}
                  role="alert"
                >
                  {message}
                </div>
              )}
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block"
                  style={{ backgroundColor: "#ff4343", color: "white" }}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Login Now"}
                </button>
              </div>
            </form>
            <h5 className="text-center text-light">
              Don't have an account?{" "}
              <Link to="/register">Create an Account</Link>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { createContext, useState, useEffect, useContext } from "react";

import { useAuth } from "./AuthContext";

const AnimeDataContext = createContext();

export const useAnimeData = () => useContext(AnimeDataContext);

export const AnimeDataProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const [topAiringAnime, setTopAiringAnime] = useState([]);
  const [topAiringPage, setTopAiringPage] = useState(1);
  const [topAiringHasNextPage, setTopAiringHasNextPage] = useState(true);

  const [popularAnime, setPopularAnime] = useState([]);
  const [popularPage, setPopularPage] = useState(1);
  const [popularHasNextPage, setPopularHasNextPage] = useState(true);

  const [recentAnime, setRecentAnime] = useState([]);
  const [recentPage, setRecentPage] = useState(1);
  const [recentHasNextPage, setRecentHasNextPage] = useState(true);

  const fetchAnimeData = async (type, page) => {
    try {
      if (!currentUser) {
        return { results: [], hasNextPage: false };
      }
      setLoading(true);
      const response = await fetch(
        `https://consumet.animetv24.com/anime/gogoanime/${type}?page=${page}`
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch ${type} anime data`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching ${type} anime data:`, error);
      return { results: [], hasNextPage: false };
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!currentUser) return;
        setLoading(true);

        const topAiringData = await fetchAnimeData("top-airing", 1);
        setTopAiringAnime(topAiringData.results);
        setTopAiringHasNextPage(topAiringData.hasNextPage);

        const popularData = await fetchAnimeData("popular", 1);
        setPopularAnime(popularData.results);
        setPopularHasNextPage(popularData.hasNextPage);

        const recentData = await fetchAnimeData("recent-episodes", 1);
        setRecentAnime(recentData.results);
        setRecentHasNextPage(recentData.hasNextPage);
      } finally {
        setLoading(false);
      }
    };

    if (
      topAiringAnime.length === 0 &&
      popularAnime.length === 0 &&
      recentAnime.length === 0
    ) {
      fetchInitialData();
    }
  }, [currentUser]);

  const loadMoreAnime = async (type) => {
    if (!currentUser) return;
    let nextPage;
    switch (type) {
      case "top-airing":
        nextPage = topAiringPage + 1;
        const topAiringData = await fetchAnimeData(type, nextPage);
        setTopAiringAnime((prevData) => [
          ...prevData,
          ...topAiringData.results,
        ]);
        setTopAiringHasNextPage(topAiringData.hasNextPage);
        setTopAiringPage(nextPage);
        break;
      case "popular":
        nextPage = popularPage + 1;
        const popularData = await fetchAnimeData(type, nextPage);
        setPopularAnime((prevData) => [...prevData, ...popularData.results]);
        setPopularHasNextPage(popularData.hasNextPage);
        setPopularPage(nextPage);
        break;
      case "recent-episodes":
        nextPage = recentPage + 1;
        const recentData = await fetchAnimeData(type, nextPage);
        setRecentAnime((prevData) => [...prevData, ...recentData.results]);
        setRecentHasNextPage(recentData.hasNextPage);
        setRecentPage(nextPage);
        break;
      default:
        console.warn(`Unknown anime type: ${type}`);
    }
  };

  const value = {
    topAiringAnime,
    loadMoreTopAiringAnime: () => loadMoreAnime("top-airing"),
    topAiringHasNextPage,
    popularAnime,
    loadMorePopularAnime: () => loadMoreAnime("popular"),
    popularHasNextPage,
    recentAnime,
    loadMoreRecentAnime: () => loadMoreAnime("recent-episodes"),
    recentHasNextPage,
    loading,
  };

  return (
    <AnimeDataContext.Provider value={value}>
      {children}
    </AnimeDataContext.Provider>
  );
};

export default AnimeDataProvider;

import React from "react";
import Recent from "./Recent";

const RecentIndex = () => {
  return (
    <div>
      <Recent />
    </div>
  );
};

export default RecentIndex;

import React from "react";
import { useAnimeData } from "../context/AnimeDataProvider";
import { Link } from "react-router-dom";

const Recent = () => {
  const { recentAnime, loadMoreRecentAnime, recentHasNextPage, loading } =
    useAnimeData();

  const loadMore = (e) => {
    e.preventDefault();
    loadMoreRecentAnime();
  };

  return (
    <div className="container mt-3 rounded p-3">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-8">
          <div className="section-title">
            <h4>Recently Released Anime</h4>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 mt-3 mb-3">
        {recentAnime.map((anime) => (
          <div key={anime.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="product__item">
              <div
                className="product__item__pic set-bg"
                style={{ backgroundImage: `url(${anime.image})` }}
              >
                <div className="ep">
                  {"Episode "}
                  {anime.episodeNumber}
                </div>
              </div>
              <div className="product__item__text">
                <h5 className="text-truncate text-light">
                  <Link to={`/anime/${anime.id}`}>
                    {anime.title ? anime.title : anime.id}
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      {recentHasNextPage && (
        <div className="d-flex justify-content-center mb-5">
          {!loading && (
            <button className="btn btn-danger" onClick={loadMore}>
              Load More
            </button>
          )}
          {loading && (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden"></span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Recent;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Jumbotron() {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    try {
      navigate(`/search/${query}`);
    } catch (error) {
      console.error("Search error:", error);
    }
  };
  return (
    <div
      className="jumbotron jumbotron-fluid"
      style={{ backgroundColor: "#161645" }}
    >
      <div className="container ">
        <h1 className="   text-center text-light fw-bold">
          Watch Free Anime Online
        </h1>
        <p className="lead text-center text-light">
          Explore a vast collection of anime titles for free streaming.
        </p>

        <div className="d-flex justify-content-center">
          <form className="form-inline mt-4" onSubmit={handleSearch}>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button className="btn btn-danger my-2 my-sm-0" type="submit">
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

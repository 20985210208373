import React from "react";

export default function NotFound() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-50">
      <h1 className="display-1 text-light">404</h1>
      <h2 className="mb-4 text-light">Page Not Found</h2>
      <img width={250} src="/assets/img/sad.gif" alt="404" className="mb-3" />
    </div>
  );
}

import React from "react";
import SearchedAnime from "./SearchedAnime";

import { useParams } from "react-router-dom";

export default function IndexSearch() {
  const { query } = useParams();

  return <SearchedAnime query={query} />;
}

import React from "react";
import ShowComments from "./ShowComments";
import AnimeSuggestions from "./AnimeSuggestions";
import { Link } from "react-router-dom";
export default function Details({ animeInfo }) {
  if (!animeInfo) {
    return <div className="text-light">No anime details available.</div>;
  }

  return (
    <div className="container">
      <div className="anime__details__content">
        <div className="row">
          <div className="col-lg-3">
            <div
              className="anime__details__pic set-bg"
              style={{ backgroundImage: `url(${animeInfo.image})` }}
            ></div>
          </div>
          <div className="col-lg-9">
            <div className="anime__details__text">
              <div className="anime__details__title">
                <h3>{animeInfo.title}</h3>
                <span>{animeInfo.otherName}</span>
              </div>
              <p>{animeInfo.description}</p>

              <div className="anime__details__widget">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ul>
                      <li>
                        <span>Type:</span> {animeInfo.type}
                      </li>
                      <li>
                        <span>Language:</span> {animeInfo.subOrDub}
                      </li>
                      <li>
                        <span>Date aired:</span> {animeInfo.releaseDate}{" "}
                      </li>
                      <li>
                        <span>Total Episodes:</span> {animeInfo.totalEpisodes}
                      </li>
                      <li>
                        <span>Status:</span> {animeInfo.status}
                      </li>
                      <li>
                        <span>Genre:</span> {animeInfo.genres.join(", ")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="anime__details__btn">
                <a href="#" className="follow-btn">
                  <i className="fa fa-heart-o"></i> Add to Favorite
                </a>
                <Link className="watch-btn" to={`/watch/${animeInfo.id}`}>
                  <span>Watch Now</span> <i className="fa fa-angle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <ShowComments />
          <AnimeSuggestions />
        </div>
      </div>
    </div>
  );
}

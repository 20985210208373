import React from "react";

export default function AnimeSuggestions() {
  return (
    <div className="col-lg-4 col-md-4">
      <div className="anime__details__sidebar">
        <div className="section-title">
          <h5>AnimeTV 24 Top Suggestions</h5>
        </div>
        <div
          className="product__sidebar__view__item set-bg"
          style={{ backgroundImage: `url(/assets/img/sidebar/tv-1.jpg)` }}
        >
          <div className="ep">18 / ?</div>
          <div className="view">
            <i className="fa fa-eye"></i> 9141
          </div>
          <h5>
            <a href="#">Boruto: Naruto next generations</a>
          </h5>
        </div>
        <div
          className="product__sidebar__view__item set-bg"
          style={{ backgroundImage: `url(/assets/img/sidebar/tv-2.jpg)` }}
        >
          <div className="ep">18 / ?</div>
          <div className="view">
            <i className="fa fa-eye"></i> 9141
          </div>
          <h5>
            <a href="#">The Seven Deadly Sins: Wrath of the Gods</a>
          </h5>
        </div>
        <div
          className="product__sidebar__view__item set-bg"
          style={{ backgroundImage: `url(/assets/img/sidebar/tv-3.jpg)` }}
        >
          <div className="ep">18 / ?</div>
          <div className="view">
            <i className="fa fa-eye"></i> 9141
          </div>
          <h5>
            <a href="#">Naruto: Shippuden</a>
          </h5>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import WriteComment from "./WriteComment";

export default function ShowComments() {
  return (
    <div className="col-lg-8 col-md-8">
      <div className="anime__details__review">
        <div className="section-title">
          <h5>Reviews</h5>
        </div>
        <div className="anime__review__item">
          <div className="anime__review__item__pic">
            <img src="/assets/img/anime/review-1.jpg" alt="" />
          </div>
          <div className="anime__review__item__text">
            <h6>
              Chris Curry - <span>1 Hour ago</span>
            </h6>
            <p>
              whachikan Just noticed that someone categorized this as belonging
              to the genre "demons" LOL
            </p>
          </div>
        </div>
        <div className="anime__review__item">
          <div className="anime__review__item__pic">
            <img src="/assets/img/anime/review-2.jpg" alt="" />
          </div>
          <div className="anime__review__item__text">
            <h6>
              Lewis Mann - <span>5 Hour ago</span>
            </h6>
            <p>Finally it came out ages ago</p>
          </div>
        </div>
        <div className="anime__review__item">
          <div className="anime__review__item__pic">
            <img src="/assets/img/anime/review-3.jpg" alt="" />
          </div>
          <div className="anime__review__item__text">
            <h6>
              Louis Tyler - <span>20 Hour ago</span>
            </h6>
            <p>Where is the episode 15 ? Slow update! Tch</p>
          </div>
        </div>
        <div className="anime__review__item">
          <div className="anime__review__item__pic">
            <img src="/assets/img/anime/review-4.jpg" alt="" />
          </div>
          <div className="anime__review__item__text">
            <h6>
              Chris Curry - <span>1 Hour ago</span>
            </h6>
            <p>
              whachikan Just noticed that someone categorized this as belonging
              to the genre "demons" LOL
            </p>
          </div>
        </div>
        <div className="anime__review__item">
          <div className="anime__review__item__pic">
            <img src="/assets/img/anime/review-5.jpg" alt="" />
          </div>
          <div className="anime__review__item__text">
            <h6>
              Lewis Mann - <span>5 Hour ago</span>
            </h6>
            <p>Finally it came out ages ago</p>
          </div>
        </div>
        <div className="anime__review__item">
          <div className="anime__review__item__pic">
            <img src="/assets/img/anime/review-6.jpg" alt="" />
          </div>
          <div className="anime__review__item__text">
            <h6>
              Louis Tyler - <span>20 Hour ago</span>
            </h6>
            <p>Where is the episode 15 ? Slow update! Tch</p>
          </div>
        </div>
      </div>
      <WriteComment />
    </div>
  );
}

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Player from "./Player";
import { useAnimeInfo } from "../context/AnimeInfoProvider";

export default function PlayerIndex() {
  const { id } = useParams();
  const { animeInfo, fetchAnimeInfo, loading, error } = useAnimeInfo();

  useEffect(() => {
    fetchAnimeInfo(id);
  }, [id]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div class="spinner-border text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-3 text-danger">Error: {error.message}</div>
    );
  }

  if (!animeInfo) {
    return (
      <div className="container mt-3">No anime information available.</div>
    );
  }

  return (
    <div className="container mt-4">
      <Player animeInfo={animeInfo} />
    </div>
  );
}

import React from "react";

export default function WriteComment() {
  return (
    <div className="anime__details__form">
      <div className="section-title">
        <h5>Your Comment</h5>
      </div>
      <form action="#">
        <textarea placeholder="Your Comment"></textarea>
        <button type="submit">
          <i className="fa fa-location-arrow"></i> Review
        </button>
      </form>
    </div>
  );
}

import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import NotFound from "./components/NotFound";

import Home from "./components/home/Home";
import PopularIndex from "./components/popular/Index";
import RecentIndex from "./components/recent/Index";
import IndexSearch from "./components/search/IndexSearch";
import DetailsIndex from "./components/anime/DetailsIndex";

import AnimeDataProvider from "./components/context/AnimeDataProvider";
import AuthProvider from "./components/context/AuthContext";
import { AnimeInfoProvider } from "./components/context/AnimeInfoProvider";
import { SearchAnimeProvider } from "./components/context/SearchAnimeProvider";

import ProtectedRoute from "./components/utils/ProtectedRoute";
import PlayerIndex from "./components/anime/PlayerIndex";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <AnimeDataProvider>
            <SearchAnimeProvider>
              <AnimeInfoProvider>
                <Navbar />
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/anime/:id" element={<DetailsIndex />} />
                    <Route path="/watch/:id" element={<PlayerIndex />} />
                    <Route path="/popular" element={<PopularIndex />} />
                    <Route path="/recent" element={<RecentIndex />} />
                    <Route path="/search/:query" element={<IndexSearch />} />
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
                {/* <Footer /> */}
              </AnimeInfoProvider>
            </SearchAnimeProvider>
          </AnimeDataProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSearchAnime } from "../context/SearchAnimeProvider";

export default function SearchedAnime({ query }) {
  const {
    searchResults,
    search,
    loadMoreSearchResults,
    loading,
    searchHasNextPage,
  } = useSearchAnime();

  useEffect(() => {
    if (query) {
      search(query);
    }
  }, []);

  const loadMore = (e) => {
    e.preventDefault();
    loadMoreSearchResults();
  };

  return (
    <div className="container mt-3 mb-5">
      <h2 className="text-light mt-2 mb-3">Results for "{query}"</h2>

      <div className="row">
        {query.trim() === "" ? (
          <p className="text-light">Search term is not valid.</p>
        ) : (
          searchResults.map((anime) => (
            <div key={anime.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div className="product__item">
                <div
                  className="product__item__pic set-bg"
                  style={{ backgroundImage: `url(${anime.image})` }}
                >
                  <div className="ep">{anime.releaseDate}</div>
                  <div className="view">{anime.subOrDub}</div>
                </div>
                <div className="product__item__text">
                  <h5 className="text-truncate text-light">
                    <Link to={`/anime/${anime.id}`}>
                      {anime.title ? anime.title : anime.id}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {searchHasNextPage && (
        <div className="d-flex justify-content-center mb-5">
          {!loading ? (
            <button className="btn btn-danger" onClick={loadMore}>
              Load More
            </button>
          ) : (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden"></span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

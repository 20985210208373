import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function Register() {
  const { signup } = useAuth();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    setMessage("");
    setError(false);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError(true);
      setMessage("Invalid email address");
      return;
    }

    setLoading(true);
    try {
      await signup(username, password, email);
      setLoading(false);
      setMessage("Account created successfully. Please log in.");
      navigate("/login");
    } catch (err) {
      setLoading(false);
      setError(true);
      setMessage("Failed to create account. Please try again.");
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-6 bg-transparent text-light">
          <div className="card p-4 bg-transparent">
            <h3 className="card-title text-center text-light fw-bold">
              Create an Account
            </h3>
            <form onSubmit={handleRegister}>
              <div className="form-group">
                <label>Email address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {message && (
                <div
                  className={`alert ${
                    error ? "alert-danger" : "alert-success"
                  }`}
                  role="alert"
                >
                  {message}
                </div>
              )}
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block"
                  style={{ backgroundColor: "#ff4343", color: "white" }}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Create an Account"}
                </button>
              </div>
            </form>
            <h5 className="mt-4 text-center text-light">
              Already have an account? <Link to="/login">Log In!</Link>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
